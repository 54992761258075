<template>
<div>
  <van-cell-group title="基本信息">
    <van-cell title="学校" v-model="model.SCHOOL.FULL_NAME" size="large" />
    <van-cell title="姓名" v-model="model.NAME" size="large" />
    <van-cell title="手机" v-model="model.MOBILE" size="large"/>
    <van-cell title="角色" v-model="model.ROLE" size="large"/>
  </van-cell-group>
</div>
</template>

<script>
export default {
  data(){
    return {
      model:{SCHOOL:{}}
    }
  },
  created() {
    this.getMine()
  },
  methods:{
    getMine(){
      let self=this;
      this.whale.remote.getResult({
        url:"/api/Mobile/ORG/TeacherApi/Mine",
        completed(m){
          console.log('ttt',m)
          self.model=m.DATA;
        }
      })
    }
  }
}
</script>

<style scoped></style>